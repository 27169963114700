import React from 'react'

import * as st from '~/assets/styl/Politics.module.styl'

const Politics = ({ title, content }: PageProps) => {
  return (
    <div className={st.politicsContainer}>
      <div className={st.politicsWrapper}>
        <aside>
          <span>{title}</span>
        </aside>
        <div
          className={st.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

export default Politics
