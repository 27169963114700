import React from 'react'
import { Head } from '~/components'
import { useApi } from '~/api'
import Footer from '~/components/Footer'
import Politics from '~/components/Politics'
import { PageProps } from 'gatsby'

type PageContext = {
  name: string
  content: string
  slug: string
  default: boolean
  url: string
}

const Paginas = ({ location, pageContext }: PageProps) => {
  const { name, content } = useApi(
    pageContext,
    `pages/${(pageContext as PageContext).slug}`
  ) as PageContext

  return (
    <>
      <Head
        location={location}
        title={name + ' - ' + process.env.GATSBY_SITE_NAME}
      />
      <Politics title={name} content={content} />
      <Footer />
    </>
  )
}

export default Paginas
